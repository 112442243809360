<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"/>
        </template>
        <template v-slot:formObservations>
            <FormMDUObservations :field="form.field.shape" :allPositions="form.positions"
                                 ref="observationsView" v-model:step="step" v-model:form="form" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMDUObservations from "./components/FormMDUObservations.vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {DateUtils} from "@/utils/DateUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "FormMDUView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMDUObservations,
        },

        data() {
            return {
                programCode: "MDU",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.form.programName),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
                readonly: computed(() => this.readonly),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.positions, "position");
                        for (let i = 0; i < this.form.positions.length; i += 1) {
                            const position = this.form.positions[i];
                            for (let j = 1; j <= 2; j += 1) {
                                const control = position[`control${j}`];
                                if (control != null) {
                                    if (control.startTime !== null) {
                                        control.startTime = control.startTime.substring(0, 5);
                                    }
                                    if (control.endTime !== null) {
                                        control.endTime = control.endTime.substring(0, 5);
                                    }
                                } else {
                                    position[`control${j}`] = {};
                                }
                            }
                        }
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (this.readonly) {
                            this.loaded = true;
                        } else if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                            this.loaded = false;
                        } else {
                            this.loaded = false;
                        }
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const p = this.form.positions[i];
                        ValidateUtils.formatControlDateStartTimeAndEndTime(p.control1);
                        ValidateUtils.formatControlDateStartTimeAndEndTime(p.control2);
                        p.control1.observationTime = DateUtils.formatFormTime(p.control1.observationTime);
                        p.control2.observationTime = DateUtils.formatFormTime(p.control2.observationTime);
                        const positionRequest = ValidateUtils.getGeobjectRequestWithHabitatData(p, p.controlDate, this.programCode);
                        request.positions.push(positionRequest);
                    }
                }
                return request;
            },
            validateControl(control, i) {
                let isCorrect = true;
                if (control.noControl !== true) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["controlDate", "startTime", "endTime", "clouds", "wind", "rain"]) && isCorrect;
                    isCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.dicts.controlType[i].controlStart, this.dicts.controlType[i].controlEnd) && isCorrect;
                    if (control.noBirds !== true) {
                        isCorrect = ValidateUtils.validateNotEmpty(control, ["countingMethod", "observationTime", "minimum", "maximum"]) && isCorrect;
                        if (control.minimum > control.maximum) isCorrect = false;
                        if (control.countingMethod?.code === "1") isCorrect = ValidateUtils.validateNotEmpty(control, ["count1", "count2", "count3", "activity"]) && isCorrect;
                    }
                }
                if (control.noControl) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["noControlReason"]) && isCorrect;
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        let positionIsCorrect = true;
                        const position = this.form.positions[i];
                        if (position.notAudited == null || position.notAudited === false) {
                            positionIsCorrect = this.validateControl(position.control1, 0) && positionIsCorrect;
                            positionIsCorrect = this.validateControl(position.control2, 1) && positionIsCorrect;
                            if (this.form.habitatDataToChange != null && this.form.habitatDataToChange.habitatType == null) positionIsCorrect = false;
                        }
                        if (position.habitatDataToChange != null) {
                            positionIsCorrect = ValidateUtils.validateNotEmpty(position.habitatDataToChange, [
                                "habitatType", "utilization", "water", "spatial", "succession", "averageHeightPlant", "averageHeightClump",
                            ]) && positionIsCorrect;
                        }
                        if (position.error) delete position.error; // remove previous checks
                        if (!positionIsCorrect) {
                            position.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
            readonly() {
                return SystemRole.isObserver() && this.form.state === "APPROVED";
            },
        },
    };
</script>

<style lang="scss">
</style>
