<template>
    <DataTable :value="mammals" v-model:editingRows="editingRows" editMode="row" dataKey="code" lazy
               :totalRecords="totalRecords" :loading="loading" :search-criteria="searchCriteria"
               @page="onPage" @sort="onPage" @row-edit-save="onRowEditSave"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink
               LastPageLink CurrentPageReport RowsPerPageDropdown" removableSort
               currentPageReportTemplate="Pozycji na stronie" paginator
               :rows="10" :rowsPerPageOptions="[5,10,20,50]" size="small"
               v-model:rows="page.limit" v-model:first="page.offset">
        <Column header="Kod" field="code" sortable>
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="code" />
            </template>
        </Column>
        <Column header="Nazwa polska" field="namePl" sortable>
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="namePl" />
            </template>
        </Column>
        <Column header="Nazwa angielska" field="nameEn" sortable>
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameEn" />
            </template>
        </Column>
        <Column header="Nazwa łacińska" field="nameLat" sortable>
            <template #editor="{data,field}">
                <CustomInputText v-model="data[field]" name="nameLat" />
            </template>
        </Column>
        <Column :rowEditor="true" style="text-align: center;" class="w-1"/>
        <!-- <Column style="padding-left: 0; padding-right: 0; width: 120px">
            <template #body="slotProps">
                <div>
                    <CustomButton icon="pi pi-pencil" label="Więcej" @click="goToEditMammal(slotProps.data.id)" />
                </div>
            </template>
        </Column> -->
        <Column style="width: 4.1666%; text-align: center;">
            <template #body="slotProps">
                <CustomButton icon="pi pi-trash" borderColor="transparent"
                              @click="deleteMammalDict(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchMammals,
        countMammals,
        updateMammal,
        // getMammal,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "MammalsDictTable",

        components: {
            DataTable,
            Column,
            CustomInputText,
            CustomButton,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                mammals: [],
                editingRows: ref([]),
                loading: false,
                totalRecords: 0,
                page: SearchCriteria.getClearPage(),
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.page.offset = event.first;
                this.page.limit = event.rows;
                this.page.sortField = event.sortField;
                this.page.sortOrder = event.sortOrder;

                const criteria = {
                    ...this.searchCriteria,
                    page: this.page,
                };

                searchMammals({body: criteria})
                    .then((response) => {
                        this.mammals = response.data;
                        this.loading = false;
                    });
            },

            updateTotalRecords() {
                countMammals({body: this.searchCriteria})
                    .then((response) => {
                        this.totalRecords = response.data;
                    });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                };
            },

            onRowEditSave(event) {
                const {newData, index} = event;
                updateMammal({id: newData.id, body: newData})
                    .then(() => {
                        this.mammals[index] = newData;
                    });
            },

            // goToEditMammal(id) {
            //     this.$router.push(`/mammal-dict/${id}`);
            // },

            deleteMammalDict(item) {
                const index = this.mammals.indexOf(item);
                this.mammals.splice(index, 1);
            },
        },
    };
</script>

<style lang="scss">
</style>
