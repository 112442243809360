<template>
    <div class="p-3">
        <h2>Dodaj ssaka</h2>
        <div class="flex gap-3 mb-2 flex-column flex-direction-column">
            <CustomInputText name="code" label="Kod" v-model="newMammal.code" class="w-4 max-width" />
            <CustomInputText name="namePl" label="Nazwa polska" v-model="newMammal.namePl" class="w-4 max-width" />
            <CustomInputText name="nameEn" label="Nazwa angielska" v-model="newMammal.nameEn" class="w-4 max-width" />
            <CustomInputText name="nameLat" label="Nazwa łacińska" v-model="newMammal.nameLat" class="w-4 max-width" />
        </div>
        <div class="flex gap-2 justify-content-end w-4 max-width">
            <CustomButton label="Zapisz" icon="pi pi-pencil" class="mt-3"
                          bgColor="var(--secondary-color)" color="var(--surface-a)"
                          @click="save" style="min-width: 112px;" />
            <CustomButton label="Anuluj" class="mt-3" @click="goBack" style="min-width: 76px;"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {
        createMammal,
    } from "@/swagger/vue-api-client";

    export default {
        name: "CreateMammalView",

        components: {
            CustomInputText,
            CustomButton,
        },

        data() {
            return {
                newMammal: {
                    code: null,
                    namePl: null,
                    nameEn: null,
                    nameLat: null,
                },
            };
        },

        methods: {
            save() {
                createMammal({body: this.newMammal})
                    .then(() => {
                        this.$router.push("/dicts/mammals");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 800px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
        .max-width {
            width: 100% !important;
        }
    }
</style>
