<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2 w-full flex-wrap">
            <CustomMultiSelect v-if="programs !== null" id="programFilter"
                               v-model="internalValue.programs" name="programs"
                               :items="programs" label="Program" class="w-10rem"
                               itemLabel="" @update:modelValue="update"/>
            <CustomInputText name="code" label="Kod" id="code" v-model="internalValue.code" class="w-12rem"
                             @update:modelValue="update" />
        </div>
        <CustomButton label="Wyczyść" style="min-width: 90px;" />
    </div>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {getListOfPrograms} from "@/swagger/vue-api-client";

    export default {
        name: "EnumDictSearchCriteria",

        data() {
            return {
                programs: [],
            };
        },

        mounted() {
            getListOfPrograms()
                .then((response) => {
                    this.programs = response.data;
                });
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        components: {
            CustomMultiSelect,
            CustomInputText,
            CustomButton,
        },

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>
