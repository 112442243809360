<template>
    <Toast />
    <div>
        <div class="accordion-like-banner">{{form.programFullName ? form.programFullName : "Dane podstawowe"}}</div>
        <div class="flex align-items-center justify-content-between p-3">
            <ProgramDetails :form="form" :showControlType="showControlType" :transect="transect"/>
            <div class="flex gap-3 flex-wrap-reverse">
                <span class="flex align-items-center justify-content-end">Pola oznaczone * są obowiązkowe</span>
                <CustomButton v-if="!editing && editable && !isObserver" label="Edytuj formularz" bgColor="var(--secondary-color)"
                              @click="$emit('update:editing',true)" color="var(--surface-a)" />
            </div>
        </div>
        <div v-if="isStep1 && !dontShowNoFormControlCheckbox" class="flex w-full gap-3 px-3 pb-3">
            <CustomAlertBox class="flex justify-content-start w-full" :disabled="!editing"
                            text="Kontrola nie została wykonana" v-model="internalForm.noFormControl"/>
        </div>
        <div v-if="internalForm.noFormControl" class="px-3">
            <CustomSelectOne label="Przyczyna braku kontroli" name="noFormControlReason"
                             v-model="internalForm.noFormControlReason" :disabled="!editing"
                             :items="internalControlNotRealizedReason"
                             required :showErrors="showErrorMessages" :filter="false"/>
            <CustomInputTextArea label="Uwagi" name="controlRealizationNotes"
                                 v-model="internalForm.noFormControlNotes" :disabled="!editing" />
        </div>
        <slot v-else-if="isStep1" name="formMap"/>
        <slot v-if="isStep2 && !internalForm.noFormControl && !noPosition" name="formObservations"/>
        <SaveForm :request="request"
                  :validationCorrect="validationCorrect"
                  :state="form.state"
                  :programEditionId="form.programEditionId"
                  @validate="$emit('validate')"
                  @goTo2Step="$emit('goTo2Step')"
                  :someGeobjectWasAudited="someGeobjectWasAudited"
                  :noFormControl="internalForm.noFormControl || noPosition"
                  :onlyStepOne="onlyStepOne"
                  :onlyStepTwo="onlyStepTwo"
                  v-model:showErrorMessages="internalShowErrorMessages"
                  v-model:step="internalStep"
                  :isDynamicPosition="isDynamicPosition"
                  :someGeobjectLength="someGeobjectLength"/>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import CustomButton from "@/components/form/CustomButton.vue";
    import SaveForm from "@/components/formSharedComponents/SaveForm.vue";
    import ProgramDetails from "@/components/formSharedComponents/ProgramDetails.vue";
    import CustomAlertBox from "@/components/form/CustomAlertBox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "FormViewTemplate",

        components: {
            CustomInputTextArea,
            CustomSelectOne,
            CustomAlertBox,
            ProgramDetails,
            SaveForm,
            CustomButton,
            Toast,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            editing: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: true,
            },
            dontShowNoFormControlCheckbox: {
                type: Boolean,
                default: false,
            },
            validationCorrect: {
                type: Boolean,
                default: false,
            },
            request: {
                type: Object,
                required: true,
            },
            someGeobjectWasAudited: {
                type: Boolean,
                default: true,
            },
            onlyStepOne: {
                type: Boolean,
                default: false,
            },
            onlyStepTwo: {
                type: Boolean,
                default: false,
            },
            showErrorMessages: {
                type: Boolean,
                default: false,
            },
            showControlType: {
                type: Boolean,
                default: false,
            },
            transect: {
                type: Boolean,
                default: false,
            },
            noPosition: {
                type: Boolean,
                default: false,
            },
            step: {
                type: Number,
            },
            isDynamicPosition: {
                type: Boolean,
                default: false,
            },
            someGeobjectLength: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                internalControlNotRealizedReason: [],
            };
        },

        inject: ["dicts"],

        emits: ["update:form", "update:editing", "update:step", "update:showErrorMessages",
                "validate", "goTo2Step"],

        beforeMount() {
            if (this.dicts.controlNotRealizedReason?.length > 0) {
                this.internalControlNotRealizedReason = this.dicts.controlNotRealizedReason.filter((cNRR) => cNRR.info === undefined || cNRR.info == null);
            }
        },

        computed: {
            isStep1() {
                return this.step === 1;
            },
            isStep2() {
                return this.step === 2;
            },
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalShowErrorMessages: {
                get() { return this.showErrorMessages; },
                set(value) { this.$emit("update:showErrorMessages", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
            isObserver() {
                return SystemRole.isObserver();
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/_variables.scss";
.accordion-like-banner {
    background: var(--secondary-color);
    color: var(--surface-a);
    padding: $accordionHeaderPadding;
    border: 0;
    font-weight: bold;
    font-size: 1.07rem;
    text-transform: uppercase;
    transition: $listItemTransition;
}
</style>
