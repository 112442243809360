<template>
    <div class="flex justify-content-between">
        <div class="flex gap-2 w-full flex-wrap">
            <CustomInputText label="Kod gatunku (MPPL)" name="ident1"
                             v-model="internalValue.ident1" @update:modelValue="update" />
            <CustomInputText label="Kod gatunku (obrączkarski)" name="ident2"
                             v-model="internalValue.ident2" @update:modelValue="update" />
            <CustomInputNumber label="Identyfikator EURING" name="identEuring"
                               v-model="internalValue.identEuring" @update:modelValue="update" />
            <CustomInputText label="Nazwa polska" name="namePl" v-model="internalValue.namePl"
                             @update:modelValue="update" />
            <CustomInputText label="Nazwa angielska" name="nameEn" v-model="internalValue.nameEn"
                             @update:modelValue="update" />
            <CustomInputText label="Nazwa łacińska" name="nameLat" v-model="internalValue.nameLat"
                             @update:modelValue="update" />
        </div>
        <CustomButton label="Wyczyść" @click="clear" style="min-width: 90px;" />
    </div>
    <div class="flex justify-content-end mb-2">
        <CustomButton label="Dodaj nowy gatunek ptaka" @click="goToAddNew" />
    </div>
</template>

<script>
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "BirdsDictSearchCriteria",

        components: {
            CustomInputText,
            CustomButton,
            CustomInputNumber,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        methods: {
            update() {
                this.$emit("update:modelValue", this.internalValue);
            },

            clear() {
                this.internalValue.ident1 = "";
                this.internalValue.ident2 = "";
                this.internalValue.identEuring = null;
                this.internalValue.namePl = "";
                this.internalValue.nameEn = "";
                this.internalValue.nameLat = "";
                this.update();
            },

            goToAddNew() {
                this.$router.push("/bird-dict/new");
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
</style>
