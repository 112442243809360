<template>
    <div class="p-3">
        <CustomSelectOne v-model="selectedType" :items="dictTypes" itemValue="code"
                         name="selectDictType" label="Słownik" class="w-4 max-width" />
        <div v-if="selectedType != null && selectedType !== ''">
            <EnumDictSearchCriteria v-model="sc" @update:modelValue="search" />
            <EnumDictTable :searchCriteria="sc" ref="table" :selectedType="selectedType" />
        </div>
    </div>
</template>

<script>
    import EnumDictTable from "./EnumDictTable.vue";
    import EnumDictSearchCriteria from "./EnumDictSearchCriteria.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "EnumDictView",

        data() {
            return {
                sc: SearchCriteria.getClearSearchCriteriaEnumDicts(),
                selectedType: null,
                dictTypes: [
                    {code: "activity", label: "Aktywność"},
                    {code: "humanActivity", label: "Aktywność ludzka"},
                    {code: "reaction", label: "Czas reakcji"},
                    {code: "rain", label: "Deszcz"},
                    {code: "dominantVegetation", label: "Dominująca roślinność"},
                    {code: "treeBushesArea", label: "Drzewa i krzewy"},
                    {code: "nestingCategory", label: "Kategoria gniazdowania"},
                    {code: "distance", label: "Kategoria odległości"},
                    {code: "clumps", label: "Kępy"},
                    {code: "when", label: "Kiedy"},
                    {code: "rut", label: "Koleiny"},
                    {code: "controlType", label: "Kontrola"},
                    {code: "nestingCriteria", label: "Kryteria lęgowości"},
                    {code: "controlMethod", label: "Metoda kontroli"},
                    {code: "breedingResult", label: "Metoda kontroli liczby młodych"},
                    {code: "countingMethod", label: "Metoda liczenia"},
                    {code: "nestPlace", label: "Miejsce na gniazdo"},
                    {code: "lossesCircumstances", label: "Okoliczności strat"},
                    {code: "sexDict", label: "Płeć"},
                    {code: "controlNotRealizedReason", label: "Przyczyna braku kontroli"},
                    {code: "treeType", label: "Rodzaj drzew"},
                    {code: "nestType", label: "Rodzaj gniazda"},
                    // {code: "controlType", label: "Rodzaj kontroli"},
                    {code: "habitatType", label: "Siedlisko"},
                    {code: "audibility", label: "Słyszalność"},
                    {code: "lossesStage", label: "Stadium start"},
                    {code: "seaState", label: "Stan morza"},
                    {code: "transectZone", label: "Strefa transektu"},
                    {code: "succession", label: "Sukcesja"},
                    {code: "plantLitter", label: "Ściółka"},
                    {code: "temperature", label: "Temperatura"},
                    {code: "thermalModernization", label: "Termomodernizacja"},
                    {code: "cane", label: "Trzcina"},
                    {code: "plantsType", label: "Typ roślinności"},
                    {code: "spatial", label: "Uwarunkowanie przestrzenne"},
                    {code: "utilization", label: "Użytkownanie"},
                    {code: "wind", label: "Wiatr"},
                    {code: "visibility", label: "Widoczność"},
                    {code: "age", label: "Wiek"},
                    {code: "water", label: "Woda"},
                    {code: "plantHeight", label: "Wysokość roślinności"},
                    {code: "clouds", label: "Zachmurzenie"},
                ],
            };
        },

        methods: {
            search() {
                this.$refs.table.search();
            },
        },

        components: {
            EnumDictTable,
            EnumDictSearchCriteria,
            CustomSelectOne,
        },
    };
</script>

<style lang="scss" scoped>
    .max-width {
        @media screen and (max-width: 865px) {
            width: 50% !important;
        }
        @media screen and (max-width: 600px) {
            width: 100% !important;
        }
    }
</style>
