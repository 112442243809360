<template>
    <div class="custom-multi-select field flex flex-column relative"
         @mouseenter="showFullLabel" @mouseout="hideFullLabel"
         @focus="showFullLabel" @blur="hideFullLabel" @click="hideFullLabel">
        <span class="p-float-label">
            <MultiSelect :id="name" :options="internalItems" :optionLabel="itemLabel" :optionValue="itemValue"
                         v-model="internalValue" :filter="filter" @hide="$emit('hide')"
                         @before-show="moveOnTop" :disabled="disabled" :dataKey="dataKey"
                         :virtual-scroller-options="scroll" :class="computedClasses"
                         :optionDisabled="itemDisabled" :autoFilterFocus="filter"
                         resetFilterOnHide ref="inputRef" />
            <CustomLabel v-if="label" :name="name" :label="label" :required="required" :disabled="disabled"
                         :longLabelSelect="longLabelSelect" ref="labelRef" />
        </span>
        <div v-show="showLabel && isHiddenLabel" class="label-tooltip">
            <div class="label-tooltip-arrow" style="bottom:0;left:50%" data-pc-section="arrow"></div>
            <div class="label-tooltip-text">{{ label }}</div>
        </div>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import {useField} from "vee-validate";
    import MultiSelect from "primevue/multiselect";
    import CustomValidationMessage from "./CustomValidationMessage";
    import CustomLabel from "./CustomLabel";

    export default {
        name: "CustomMultiSelect",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                default: "label",
            },
            itemValue: {
                type: String,
                default: "",
            },
            itemDisabled: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            filter: {
                type: Boolean,
                default: true,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            dataKey: {
                type: String,
                default: "",
            },
            preventSorting: {
                type: Boolean,
                default: false,
            },
            trash: null,
            virtualScrolling: {
                type: Boolean,
                default: false,
            },
            longLabelSelect: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "hide"],

        data() {
            return {
                internalItems: this.items ? [...this.items] : [],
                isHiddenLabel: false,
                showLabel: false,
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [...this.items];
            }
            // if (this.modelValue != null && this.modelValue !== "") {
            //     this.moveOnTop(this.modelValue);
            // }
            if ((!this.items || this.items?.length === 0) && this.internalValue && this.internalValue.length > 0) {
                this.internalItems = [...this.internalValue];
            }
            setTimeout(() => {
                if (this.$refs.labelRef && this.$refs.inputRef) {
                    this.isHiddenLabel = this.$refs.labelRef.$el.clientWidth / this.$refs.inputRef.$el.clientWidth >= 0.84;
                }
            }, 1000);
        },

        watch: {
            items(newVal) {
                this.internalItems = [...newVal];
            },
            internalValue: {
                handler(value) {
                    if ((!this.items || this.items?.length === 0) && value && value.length > 0) {
                        this.internalItems = [...value];
                    }
                },
                deep: true,
            },
        },

        methods: {
            moveOnTop() {
                const newVal = this.internalValue;
                if (this.preventSorting) return;
                let key;
                let secondKey;
                let arr = [];
                if (this.internalItems !== null && this.internalItems !== undefined && this.internalItems.length > 0) {
                    // eslint-disable-next-line
                    key = Object.keys(this.internalItems[0])[1];
                    // eslint-disable-next-line
                    secondKey = Object.keys(this.internalItems[0])[0];
                }
                if (newVal && (typeof newVal[0] === typeof this.internalItems[0]
                    && (typeof newVal[0] === "string" || typeof newVal[0] === "number"
                        || typeof newVal[0] === "boolean"))) {
                    let index;
                    for (let i = 0; i < newVal.length; i += 1) {
                        index = this.internalItems.indexOf(newVal[i]);
                        this.internalItems.splice(index, 1);
                    }
                    this.internalItems = [...newVal, ...this.internalItems];
                } else {
                    if (newVal && (typeof newVal[0] !== typeof this.internalItems[0])) {
                        for (let i = 0; i < newVal.length; i += 1) {
                            const index = this.internalItems.map((obj) => obj[secondKey]).indexOf(newVal[i]);
                            arr.push(this.internalItems[index]);
                        }
                    } else {
                        arr = [];
                        if (newVal) {
                            arr = newVal;
                        }
                    }
                    const newItems = arr.sort((a, b) => {
                        /* eslint-disable */
                        if (typeof a[key] === "boolean" || typeof b[key] === "boolean") return;

                        const first = a[key]?.toLowerCase();
                        const second = b[key]?.toLowerCase();
                        if (first < second) return -1;
                        if (first > second) return 1;
                        return 0;
                        /* eslint-enable */
                    });
                    let index;
                    for (let i = 0; i < newItems.length; i += 1) {
                        index = this.internalItems.map((obj) => obj[secondKey]).indexOf(newItems[i][secondKey]);
                        this.internalItems.splice(index, 1);
                    }
                    this.internalItems.sort((a, b) => {
                        /* eslint-disable */
                        if (typeof a[key] === "boolean" || typeof b[key] === "boolean") {
                            return (a[key] === b[key]) ? 0 : a[key] ? 1 : -1;
                        };

                        const first = a[key]?.toLowerCase();
                        const second = b[key]?.toLowerCase();

                        if (first < second) return -1;
                        if (first > second) return 1;
                        return 0;
                        /* eslint-enable */
                    });
                    this.internalItems = [...newItems, ...this.internalItems];
                }
            },
            showFullLabel() {
                this.showLabel = true;
            },
            hideFullLabel() {
                this.showLabel = false;
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            computedClasses() {
                return (this.customErrorMessage !== "" || this.showRequiredError) ? "p-invalid" : "";
            },
            scroll() {
                return this.virtualScrolling ? { itemSize: 36 } : undefined;
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue.length === 0);
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {MultiSelect, CustomValidationMessage, CustomLabel},
    };
</script>
<style lang="scss">
    .custom-multi-select {
        .p-multiselect-trigger {
            border-left: 1px solid #ced4da;
        }
        .p-multiselect {
            width: 100%;
            .p-multiselect-label {
                position: relative;
                top: 2px;
            }
        }
    }
</style>
<style lang="scss" scoped>
    .label-tooltip {
        display: inline-block;
        pointer-events: none;
        opacity: 1.056;
        z-index: 1000;
        padding: .25em 0;
        position: absolute;
        max-width: 12.5rem;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        &-text {
            background: var(--text-color);
            color: #ffffff;
            padding: 0.5rem 0.5rem;
            -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
        &-arrow {
            margin-left: -0.25rem;
            border-top-color: var(--text-color);
            position: absolute;
            scale: 2;
            width: 0;
            height: 0;
            border-left: 0.25em solid transparent;
            border-right: 0.25em solid transparent;
            border-top: 0.25em solid;
        }
    }
</style>
