<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      :someGeobjectLength="form.positions.length" isDynamicPosition>
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData" dynamicPositions/>
        </template>
        <template v-slot:formObservations>
            <FormMPWRObservations :field="form.field.shape" :allPositions="form.positions"
                                  ref="observationsView" v-model:step="step" v-model:form="form" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMPWRObservations from "./components/FormMPWRObservations.vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "FormMPWRView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMPWRObservations,
        },

        data() {
            return {
                programCode: "MPWR",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
                readonly: computed(() => this.readonly),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        ValidateUtils.flattenData(this.form.positions, "position");
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        this.form.positions.forEach((p) => {
                            p.birds.forEach((b) => {
                                this.dicts.programBirds.forEach((pB) => {
                                    if (b.species.code === pB.code) {
                                        if (pB.info) b.species.info = pB.info;
                                        if (pB.age || pB.age == null) b.species.age = pB.age;
                                        if (pB.sex || pB.sex == null) b.species.sex = pB.sex;
                                        if (pB.required || pB.required == null) b.species.required = pB.required;
                                    }
                                });
                            });
                        });
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (this.readonly) {
                            this.loaded = true;
                        } else if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                            this.loaded = false;
                        } else {
                            this.loaded = false;
                        }
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.positions[i]);
                        request.positions.push(this.form.positions[i]);
                    }
                }
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        let positionIsCorrect = true;
                        const position = this.form.positions[i];
                        if (position.notAudited == null || position.notAudited === false) {
                            positionIsCorrect = ValidateUtils.validateNotEmpty(position, ["controlDate", "startTime", "endTime", "habitatType"]);
                            if (DateUtils.isTimeEarlier(position.startTime, this.form.endTime)) positionIsCorrect = false;
                            positionIsCorrect = ValidateUtils.validateBirds(position.birds, position.noBirds, ["species", "nestingCriteria", "pairs"]) && positionIsCorrect;
                            position.birds.forEach((b, j) => {
                                for (let k = 0; k < position.birds.length; k += 1) {
                                    if (k !== j && k >= j && _.isEqual(b.species, position.birds[k].species)
                                        && _.isEqual(b.nestingCriteria, position.birds[k].nestingCriteria)) {
                                        // positionIsCorrect = false;
                                        this.addErrorToast("Co najmniej dwa ptaki tego samego gatunku mają takie samo kryterium lęgowości");
                                        break;
                                    }
                                }
                            });
                        }
                        if (position.error) delete position.error; // remove previous checks
                        if (!positionIsCorrect) {
                            position.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
            readonly() {
                return SystemRole.isObserver() && this.form.state === "APPROVED";
            },
        },
    };
</script>

<style lang="scss">
</style>
