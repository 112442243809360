<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="controls" additionalInformationInMain
                              v-model:additionalInformation="position">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex gap-3">
                <CustomSelectOne label="Gatunek" name="species" v-model="position.species" class="w-4"
                                 :emptyValue="false" itemLabel="name" required :items="dicts.programBirds"
                                 :disabled="!editing" :showErrors="showErrorMessages"
                                 style="min-width: 240px;" />
            </div>
        </template>
        <template v-slot:controlMainData="slotProps">
            <div class="flex gap-3 flex-wrap">
                <CustomCalendar label="Data liczenia" v-model="slotProps.control.controlDate" name="controlDate" class="w-4" required
                                :showErrors="showErrorMessages" :customErrorMessage="getDateErrorMessage(slotProps.control.controlDate,slotProps.index)"
                                :disabled="!editing" style="min-width: 200px;" />
                <CustomSelectOne label="Metoda kontroli" name="controlMethod" v-model="slotProps.control.controlMethod"
                                 :emptyValue="false" class="w-4" required :disabled="!editing"
                                 :items="dicts.controlMethod" :showErrors="showErrorMessages"
                                 style="min-width: 200px;" />
            </div>
            <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="slotProps.control.noBirds"
                            class="w-4 mt-3" :disabled="!editing" />
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <FormBirdsMCY v-if="!slotProps.control.noBirds" v-model="slotProps.control" class="mt-4" :index="slotProps.index" />
        </template>
        <template v-slot:controlHabitatData>
            <HabitatDataMCY :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import FormBirdsMCY from "./FormBirdsMCY.vue";
    import HabitatDataMCY from "./HabitatDataMCY.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMCYObservations",

        components: {
            FormObservationsTemplate,
            ObservationsSelectPosition,
            FormBirdsMCY,
            HabitatDataMCY,
            CustomCalendar,
            CustomSelectOne,
            CustomCheckbox,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts", "readonly"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMCYPosition()),
                activeIndex: 0,
                control: _.cloneDeep(EmptyStructures.getEmptyMCYControl()),
                controls: [],
            };
        },

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            this.updatePositionAndControls(0);
        },

        methods: {
            updatePositionAndControls(newActiveIndex) {
                this.position = this.positions[newActiveIndex];
                if (this.position.control1 != null) {
                    this.controls[0] = this.position.control1;
                } else {
                    this.controls[0] = _.cloneDeep(EmptyStructures.getEmptyMFGPControl());
                }
                if (this.position.control2 != null) {
                    this.controls[1] = this.position.control2;
                } else {
                    this.controls[1] = _.cloneDeep(EmptyStructures.getEmptyMFGPControl());
                }
            },
            getDateErrorMessage(value, i) {
                if (this.readonly) return "";
                if (value && !ValidateUtils.validateIsDateInRange(value, this.dicts.controlType[i]?.controlStart, this.dicts.controlType[i]?.controlEnd)) {
                    return "Data musi być z przedziału " + this.dicts.controlType[i].controlStart + " - "
                        + this.dicts.controlType[i].controlEnd;
                }
                return "";
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) {
                this.updatePositionAndControls(value);
            },
            "position.positionId"() {
                this.activeIndex = this.positions.indexOf(this.position);
            },
            controls: {
                handler(value) {
                    this.position.control1 = value[0];
                    this.position.control2 = value[1];
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
