<template>
    <div class="programs-table programs-table-observer admin-table mt-4">
        <DataTable dataKey="" :value="adminObjects" selectionMode="single" lazy paginator
                   v-model:rows="page.limit" v-model:first="page.offset" :totalRecords="totalRecords"
                   @page="onPage" @sort="onPage" removableSort :rows="10" sortMode="multiple"
                   :rowsPerPageOptions="[5,10,20,50,100]" :loading="loading" size="small"
                   v-model:filters="searchCriteria" filterDisplay="menu" @update:filters="search"
                   @rowClick="goToLink">
            <Column header="Typ" field="type" class=""
                    filterField="type" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ computedType(slotProps.data.type) }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="typeFilter" v-model="filterModel.value" :items="filterLists.type"
                                       filter :itemLabel="computedType" class="" itemValue="" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Nazwa" field="toApprove" class="" sortable sortField="name">
                <template #body=slotProps>
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.toApprove.name }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Powierzchnia" field="field" class="" sortable sortField="field.name"
                    filterField="fieldIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body=slotProps>
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.field?.name }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="fieldFilter" v-model="filterModel.value" :items="filterLists.field"
                                       filter itemLabel="name" class="" itemValue="id" virtualScrolling />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Nazwa programu" field="programName" class=""
                    filterField="program" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.programName }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="programFilter" v-model="filterModel.value" :items="filterLists.programs"
                                       filter itemLabel="" class="" itemValue="" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Pełna nazwa programu" field="programFullName" class="">
                <template #body=slotProps>
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.programFullName }}
                        </div>
                    </router-link>
                </template>
            </Column>
            <Column header="Data utworzenia" field="creationDate" class=""
                    filterField="date" sortField="creationDate" :showFilterMatchModes="false"
                    sortable>
                <template #body=slotProps>
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ computedDate(slotProps.data.creationDate) }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomCalendar v-if="loadLists" name="dateFilter" inline
                                    selectionMode="range" v-model="filterModel.value"
                                    :min="new Date(filterLists.date.min)"
                                    :max="new Date(filterLists.date.max)" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Użytkownik" field="user" class="" sortable sortField="user.name"
                    filterField="userIds" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body=slotProps>
                    <router-link :to="getRouterLink(slotProps.data)">
                        <div>
                            {{ slotProps.data.user?.name }}
                        </div>
                    </router-link>
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="userFilter" v-model="filterModel.value" :items="filterLists.user"
                                       filter itemLabel="name" class="" itemValue="id" virtualScrolling />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import {
        administratorSearchObjects,
        administratorCountObjects,
        administratorFilterObjects,
    } from "@/swagger/vue-api-client";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";

    export default {
        name: "AdminListTable",

        components: {
            FilterClear,
            FilterApply,
            DataTable,
            Column,
            CustomMultiSelect,
            CustomCalendar,
        },

        data() {
            return {
                adminObjects: [],
                totalRecords: null,
                loading: false,
                searchCriteria: SearchCriteria.getClearSearchCriteriaAdminList(),
                page: SearchCriteria.getClearPage(),
                filterLists: {},
                loadLists: false,
            };
        },

        beforeMount() {
            this.search();
        },

        mounted() {
            administratorFilterObjects().then((response) => {
                this.filterLists = response.data;
                this.loadLists = true;
            });
        },

        methods: {
            clear() {
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaAdminList();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    administratorSearchObjects({body: {...criteria}})
                        .then((response) => {
                            this.adminObjects = response.data;
                            console.log(this.adminObjects);
                            return administratorCountObjects({body: {...criteria}});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    multiSort: event.multiSortMeta,
                    sortOrder: event.sortOrder,
                    sortField: event.sortField,
                };
                this.search();
            },
            computedType(type) {
                if (type === "POSITION") return "Stanowisko";
                if (type === "POINT") return "Punkt";
                if (type === "FIELD") return "Powierzchnia";
                if (type === "USER") return "Użytkownik";
                if (type === "TRANSECT") return "Transekt";
                return "";
            },
            computedDate(date) {
                if (date) return DateUtils.displayDate(date);
                return "";
            },
            goToLink(data) {
                this.$router.push(this.getRouterLink(data.data));
            },
            getRouterLink(rowData) {
                let path;
                if (rowData.type === "USER") {
                    path = `/${rowData.type.toLowerCase()}/edit/${rowData.toApprove.id}`;
                } else {
                    path = `/${rowData.type.toLowerCase()}/${rowData.toApprove.id}`;
                }
                return path;
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaAdminListFilterDescription();
            },
        },
    };
</script>

<style lang="scss">
    .programs-table.programs-table-observer.admin-table {
        a {
            color: var(--text-black);
            padding: 0;
            display: block;
        }
    }
</style>
