<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <div class="overflow-x-auto">
        <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row"
                   dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
                   class="navigate-table" @click="arrowsNavigateTable" scrollable scrollHeight="500px"
                   style="min-width: 1440px;">
            <template #header>
                <AddBirdsMMC v-model="internalBirds" :error="showError()"
                             :nestingCriteria1="nestingCriteria1" :nestingCriteria2="nestingCriteria2"
                             :disabledNC1="disabledNC1" :disabledNC2="disabledNC2" />
            </template>
            <Column header="Para" field="ordinal" style="width: 4.1666%;" />
            <Column header="Kontrola 1 - kryterium lęgowości" style="width: 20%" field="nestingCriteria1">
                <template #body="slotProps">
                    {{ getDictLabelWithCodePrefix(slotProps.data.nestingCriteria1) }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne name="nestingCriteria1" v-model="data[field]"
                                     :items="nestingCriteria1" filter required :showErrors="showErrorMessages"
                                     tooltip short :itemLabel="getDictLabelWithCodePrefix"
                                     :disabled="disabledNC1" />
                </template>
            </Column>
            <Column header="Kontrola 2 - kryterium lęgowości" style="width: 20%" field="nestingCriteria2">
                <template #body="slotProps">
                    {{ getDictLabelWithCodePrefix(slotProps.data.nestingCriteria2) }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne name="nestingCriteria2" v-model="data[field]"
                                     :items="nestingCriteria2" filter
                                     required :showErrors="showErrorMessages" :disabled="data.loss || disabledNC2"
                                     tooltip short :itemLabel="getDictLabelWithCodePrefix" />
                </template>
            </Column>
            <Column header="Strata między kontrolami" class="w-1" field="loss">
                <template #body="slotProps">
                    <div :class="slotProps.data.loss ? 'false-style' : 'true-style'">
                        {{ computedBoolean(slotProps.data.loss) }}
                    </div>
                </template>
                <template #editor="{data,field}">
                    <CustomCheckbox name="loss" v-model="data[field]"
                                    :disabled="!criteriaC.includes(data.nestingCriteria1?.code)"
                                    style="justify-content: center; margin-bottom: 0 !important;"/>
                </template>
            </Column>
            <Column header="Para mieszana" class="w-2" field="pair">
                <template #body="slotProps">
                    {{ slotProps.data.pair?.label }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne name="pair" v-model="data[field]" :items="dicts.pair"
                                     filter required :showErrors="showErrorMessages"/>
                </template>
            </Column>
            <Column header="Dodatkowe informacje" class="w-2" field="notesBird">
                <template #editor="{data,field}">
                    <CustomInputText name="notesBird" v-model="data[field]"/>
                </template>
            </Column>
            <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteBirdObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-if="!internalNoBirds" class="flex gap-4 justify-content-start pt-4 pb-5">
        <div class="p-2" style="border: 1px solid #dee2e6;">
            <span class="font-bold">Liczba par w kategorii A:</span> {{ computedSummary('A') }}
        </div>
        <div class="p-2" style="border: 1px solid #dee2e6;">
            <span class="font-bold">Liczba par w kategorii B:</span> {{ computedSummary('B') }}
        </div>
        <div class="p-2" style="border: 1px solid #dee2e6;">
            <span class="font-bold">Liczba par w kategorii C:</span> {{ computedSummary('C') }}
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";
    import AddBirdsMMC from "@/views/formMMC/components/AddBirdsMMC.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";

    export default {
        name: "FormBirdsMMC",

        components: {
            CustomInputText,
            AddBirdsMMC,
            DataTable,
            Column,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            disabledNC1: {
                type: Boolean,
                default: false,
            },
            disabledNC2: {
                type: Boolean,
                default: false,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts", "readonly"],

        emits: ["update:noBirds", "update:birds"],

        data() {
            return {
                editingRows: ref([]),
                nestingCriteria1: [],
                nestingCriteria2: [],
                criteriaA: ["O"],
                criteriaB: ["PR", "KT", "NP", "BU"],
                criteriaC: ["GNS", "WYS", "JAJ", "PIS", "MŁO"],
            };
        },

        beforeMount() {
            if (!this.readonly) {
                this.dicts.nestingCriteria.forEach((nC) => {
                    nC.info.forEach((inf) => {
                        if (inf.code === "K1") {
                            this.nestingCriteria1.push(nC);
                        }
                        if (inf.code === "K2") {
                            this.nestingCriteria2.push(nC);
                        }
                    });
                });
            }
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.internalBirds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
            getCategory(nestingCriteria) {
                if (nestingCriteria == null) return null;
                if (this.criteriaC.includes(nestingCriteria.code)) return "C";
                if (this.criteriaB.includes(nestingCriteria.code)) return "B";
                if (this.criteriaA.includes(nestingCriteria.code)) return "A";
                return null;
            },
            getMaximumCategory(nc1, nc2) {
                if (nc1 === "C" || nc2 === "C") return "C";
                if (nc1 === "B" || nc2 === "B") return "B";
                if (nc1 === "A" || nc2 === "A") return "A";
                return null;
            },
            getBirdCategory(bird) {
                if (bird.pair?.code !== "NIE") return null;
                const nc1 = this.getCategory(bird.nestingCriteria1);
                const nc2 = this.getCategory(bird.nestingCriteria2);
                return this.getMaximumCategory(nc1, nc2);
            },
            computedSummary(category) {
                let counter = 0;
                this.birds.forEach((bird) => {
                    const birdCategory = this.getBirdCategory(bird);
                    if (birdCategory === category) counter += 1;
                });
                return counter;
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) {
                    value.forEach((b) => {
                        if (b.loss === true) b.nestingCriteria2 = null;
                        if (!this.criteriaC.includes(b.nestingCriteria1?.code)) b.loss = false;
                    });
                    this.$emit("update:birds", value);
                },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
