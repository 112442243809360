<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex column-gap-3 flex-direction-column-640">
                <CustomCalendar label="Data liczenia" name="countDate" class="w-4 max-width"
                                v-model="position.controlDate" :disabled="!editing" required
                                :showErrors="showErrorMessages"
                                :customErrorMessage="getDateErrorMessage(position.controlDate)"/>
                <CustomTimePicker label="Czas rozpoczęcia" name="startTime" class="w-4 max-width"
                                  v-model="position.startTime" :disabled="!editing" required
                                  :showErrors="showErrorMessages"/>
                <CustomTimePicker label="Czas zakończenia" name="endTime" class="w-4 max-width"
                                  v-model="position.endTime" :disabled="!editing" required
                                  :showErrors="showErrorMessages"
                                  :customErrorMessage="getTimeErrorMessage()"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column-1040">
                <CustomInputNumber label="Odległość od użytkowanych zabudowań (m)" name="buildingDistance"
                                   v-model="position.buildingDistance" class="w-4 max-width" :disabled="!editing"
                                   :required="!position.noBirds" :showErrors="showErrorMessages"
                                   longLabel />
                <CustomSelectOne :items="internalHabitatType" label="Główne usytuowanie kolonii"
                                 name="habitatType" class="w-4 max-width" v-model="position.habitatType" :disabled="!editing"
                                 :required="!position.noBirds" :showErrors="showErrorMessages" :filter="false"/>
                <CustomSelectOne label="Metoda obserwacji" name="controlMethod" v-model="position.controlMethod"
                                 class="w-4 max-width" :items="dicts.controlMethod" required
                                 :showErrors="showErrorMessages" :disabled="!editing"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column-870">
                <CustomCheckbox label="Dodaj inny typ usytuowania" v-model="position.otherHabitat" class="w-4 max-width"
                                name="otherHabitat" :disabled="!editing"/>
                <CustomSelectOne v-if="position.otherHabitat" :items="internalHabitatType2"
                                 label="Dodatkowe usytuowanie kolonii" name="habitatType2" class="w-4 max-width"
                                 :required="position.otherHabitat" v-model="position.habitatType2"
                                 :showErrors="showErrorMessages && position.otherHabitat" :disabled="!editing"
                                 :filter="false" />
                <div class="dummy field w-4" style="height: 42px;"></div>
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirdsMCZ v-model:birds="position.birds" v-model:noBirds="position.noBirds"
                          @updateWhiteHeronCount="(v) => position.whiteHeronCount = v"
                          @updateGreyHeronCount="(v) => position.grayHeronCount = v"/>
            <div v-if="!position.noBirds" class="flex column-gap-3 mt-4 flex-direction-column-760">
                <CustomInputNumber label="Liczebność czapli siwej" name="grayHeronCount"
                                   v-model="position.grayHeronCount" disabled class="w-3 max-width" />
                <CustomInputNumber label="Liczebność czapli białej" name="whiteHeronCount"
                                   v-model="position.whiteHeronCount" disabled class="w-3 max-width" />
            </div>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import CustomTimePicker from "@/components/form/CustomTimePicker.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import FormBirdsMCZ from "@/views/formMCZ/components/FormBirdsMCZ.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMCZObservations",

        components: {
            FormObservationsTemplate,
            FormBirdsMCZ,
            ObservationsSelectPosition,
            CustomCalendar,
            CustomTimePicker,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMCZPosition()),
                otherColonyLocation: false,
                internalHabitatType: [],
                internalHabitatType2: [],
            };
        },

        beforeMount() {
            if (!this.readonly) {
                this.internalHabitatType = _.cloneDeep(this.dicts.habitatType);
                this.internalHabitatType2 = _.cloneDeep(this.dicts.habitatType);
            }
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            this.positions.forEach((p) => {
                p.grayHeronCount = 0;
                p.whiteHeronCount = 0;
                p.birds.forEach((b) => {
                    if (b.species.code === "ARDCIN") {
                        p.grayHeronCount += b.nests;
                    }
                    if (b.species.code === "EGRALB") {
                        p.whiteHeronCount += b.nests;
                    }
                });
            });
        },

        emits: ["update:step"],

        inject: ["showErrorMessages", "dicts", "editing", "controlTimeFrame", "readonly"],

        methods: {
            getDateErrorMessage(value) {
                if (value && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return "";
            },
            getTimeErrorMessage() {
                if (ValidateUtils.validateNotEmpty(this.position, ["startTime", "endTime"])
                    && DateUtils.isTimeEarlier(this.position.endTime, this.position.startTime)) {
                    return this.$t("message.validations.afterTime");
                }
                return "";
            },
            computedHabitatTypeDict(usedHabitatType) {
                const habitatTypeDict = _.cloneDeep(this.dicts.habitatType);
                habitatTypeDict.forEach((hT, i) => {
                    if (_.isEqual(usedHabitatType, hT) && usedHabitatType.code !== "INN") {
                        habitatTypeDict.splice(i, 1);
                    }
                });
                return habitatTypeDict;
            },
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            "position.habitatType": {
                handler(value) {
                    this.internalHabitatType2 = this.computedHabitatTypeDict(value);
                },
                deep: true,
            },
            "position.habitatType2": {
                handler(value) {
                    this.internalHabitatType = this.computedHabitatTypeDict(value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";
@media screen and (max-width: 1040px) {
    .flex-direction-column-1040 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 870px) {
    .flex-direction-column-870 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 760px) {
    .flex-direction-column-760 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 640px) {
    .flex-direction-column-640 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
