<template>
    <ImportErrorsDialog v-model="showImportErrorsDialog" :errors="errors" />
    <div class="px-3">
        <Toast/>
        <h2 class="mb-5">{{liczbaMnoga}}</h2>
        <DictSearchCriteria v-model="searchCriteria" :label="biernik.toLowerCase()" @import="importData"
                            @export="exportData" @create="navigateToCreate" :geobject="name"/>
        <DictTableTemplate v-model="searchCriteria" :name="name" :liczbaMnoga="liczbaMnoga" :liczbaPojedyncza="liczbaPojedyncza"
                           :searchGeobjects="searchGeobjects" :searchGeobjectsCount="searchGeobjectsCount"
                           :acceptGeobject="acceptGeobject" :acceptGeobjectHistory="acceptGeobjectHistory"/>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import axios from "axios";
    import { SearchCriteria } from "@/utils/SearchCriteria";
    import {FiltersUtils} from "@/utils/FiltersUtils";
    import DictSearchCriteria from "@/views/dict/DictSearchCriteria.vue";
    import {FileUtils} from "@/utils/FileUtils";
    import DictTableTemplate from "@/views/dict/DictTableTemplate.vue";
    import ImportErrorsDialog from "@/components/formSharedComponents/dialogs/ImportErrorsDialog.vue";

    export default {
        name: "DictListViewTemplate",

        components: {
            DictTableTemplate,
            DictSearchCriteria,
            Toast,
            ImportErrorsDialog,
        },

        props: {
            biernik: {
                type: String,
                required: true,
            },
            liczbaPojedyncza: {
                type: String,
                required: true,
            },
            liczbaMnoga: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            searchGeobjects: {
                type: Function,
                required: true,
            },
            searchGeobjectsCount: {
                type: Function,
                required: true,
            },
            acceptGeobject: {
                type: Function,
                required: true,
            },
            acceptGeobjectHistory: {
                type: Function,
                default: () => {},
            },
        },

        data() {
            return {
                searchCriteria: SearchCriteria.getClearSearchCriteriaDict(this.name.toUpperCase()),
                showImportErrorsDialog: false,
                errors: null,
            };
        },

        beforeMount() {
            if (FiltersUtils.loadFromSessionStorage(this.$route.path)) {
                this.searchCriteria = FiltersUtils.loadFromSessionStorage(this.$route.path);
            }
        },

        mounted() {
            if (this.$route.query.saveCorrectly === "true") {
                setTimeout(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zmiany zostały zapisane",
                        life: 2000,
                    });
                }, 100);
                this.$router.replace({query: null});
            }
        },

        methods: {
            navigateToCreate() {
                this.$router.push(`/${this.name}/create`);
            },
            exportData() {
                FileUtils.downloadFile({}, `${this.liczbaMnoga}.xlsx`, `/${this.name}/export`);
            },
            importData(importFile) {
                axios.post(
                    `${process.env.VUE_APP_API_URL}/${this.name}/import`,
                    importFile,
                    {headers: {"Content-Type": "multipart/form-data"}})
                    .then((response) => {
                        console.log(response);
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 500) {
                            this.errors = {code: "Niepoprawny plik"};
                        } else {
                            this.errors = err.response.data;
                        }
                        this.showImportErrorsDialog = true;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
        },

        watch: {
            searchCriteria: {
                handler(value) { FiltersUtils.saveToSessionStorage(this.$route.path, value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss"></style>
