<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <FormPositions v-model="form.points" :field="form.field" @reloadFormData="getFormData" objectBeingChanged="punkt"/>
        </template>
        <template v-slot:formObservations>
            <FormMPPMObservations v-model:form="form" v-model:step="step"
                                  ref="observationsView" :field="form.field.shape" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import _ from "lodash";
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMPPMObservations from "./components/FormMPPMObservations.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "FormMPPMView",

        components: {
            FormMPPMObservations,
            FormViewTemplate,
            FormPositions,
        },

        data() {
            return {
                programCode: "MPPM",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
                readonly: computed(() => this.readonly),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.points, "point");
                        this.mergeHabitatData();
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (this.readonly) {
                            this.loaded = true;
                        } else if (err.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                            this.loaded = false;
                        } else {
                            this.loaded = false;
                        }
                    });
            },
            flattenHabitatData(hd, point, editable) {
                hd.pointId = point.pointId;
                hd.ordinal = point.ordinal;
                hd.editable = editable;
                hd.name = point.name;
                return hd;
            },
            updateHabitatDataToChangeIndexes() {
                this.form.habitatDataToChange.map((hd) => {
                    if (hd.ordinal !== null && hd.ordinal !== undefined) {
                        hd.pointId = this.form.habitatData[hd.ordinal - 1].pointId;
                        return hd;
                    }
                    return [];
                });
            },
            // isHabitatDataEmpty(hd) {
            //     return hd.habitatType == null && hd.habitatType2 == null && hd.thermalModernization == null
            //         && hd.swiftBoxes == null;
            // },
            getHabitatDataRequest(point) {
                // && !this.isHabitatDataEmpty(point.habitatData)
                if (point.habitatData !== null) {
                    return point.habitatData;
                }
                let foundHabitatDataToChange = null;
                this.form.habitatDataToChange.forEach((hd) => {
                    if (hd.name === point.name) {
                        foundHabitatDataToChange = {...hd};
                        delete foundHabitatDataToChange.ordinal;
                    }
                });
                return foundHabitatDataToChange;
            },
            getPointRequest() {
                this.updateHabitatDataToChangeIndexes();
                const allPointsRequest = [];
                this.form.points.forEach((point) => {
                    const pointRequest = {...point};
                    const habitatDataRequest = this.getHabitatDataRequest(point);
                    if (habitatDataRequest == null) {
                        delete pointRequest.habitatData;
                    } else {
                        pointRequest.habitatData = habitatDataRequest;
                    }
                    delete pointRequest.habitatDataToChange;
                    delete pointRequest.id;
                    delete pointRequest.name;
                    delete pointRequest.status;
                    delete pointRequest.transectHistoryId;
                    allPointsRequest.push(pointRequest);
                });
                return allPointsRequest;
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "points");
                if (!this.form.noFormControl) {
                    ValidateUtils.formatControlDateStartTimeAndEndTime(this.form);
                    ValidateUtils.copyFieldValues(this.form, request, ["controlDate", "startTime", "endTime", "notes", "additionalObservers"]);
                    for (let i = 0; i < this.form.points.length; i += 1) {
                        this.form.points[i].pointStartTime = DateUtils.formatFormTime(this.form.points[i].pointStartTime);
                        this.form.points[i].pointEndTime = DateUtils.formatFormTime(this.form.points[i].pointEndTime);
                    }
                    request.points = this.getPointRequest();
                }
                return request;
            },
            mergeHabitatData() {
                const habitatData = [];
                const habitatDataToChange = [];
                for (let i = 0; i < this.form.points.length; i += 1) {
                    const point = this.form.points[i];
                    // eslint-disable-next-line
                    if (point.notAudited === true) continue;
                    if (point.habitatData == null) {
                        if (point.habitatDataToChange === null) {
                            const emptyHabitatData = _.cloneDeep(EmptyStructures.getEmptyMPPMHabitatData());
                            habitatData.push(this.flattenHabitatData(emptyHabitatData, point, true));
                            point.habitatData = emptyHabitatData;
                        } else {
                            point.habitatData = point.habitatDataToChange;
                            point.habitatDataToChange = null;
                            habitatData.push(this.flattenHabitatData(point.habitatData, point, true));
                        }
                    } else {
                        habitatData.push(this.flattenHabitatData(point.habitatData, point, false));
                        point.habitatData = null;
                    }
                    if (point.habitatDataToChange !== null) {
                        habitatDataToChange.push(this.flattenHabitatData(point.habitatDataToChange, point, true));
                    }
                    this.form.habitatData = habitatData;
                    this.form.habitatDataToChange = habitatDataToChange;
                }
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = ValidateUtils.validateNotEmpty(this.form, ["controlDate", "startTime", "endTime"]);
                    isCorrect = ValidateUtils.validateIsDateInRange(this.form.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && isCorrect;
                    if (DateUtils.isTimeEarlier(this.form.endTime, this.form.startTime)) isCorrect = false;
                    isCorrect = this.validateFirstStep() && isCorrect;
                    for (let i = 0; i < this.form.points.length; i += 1) {
                        let pointIsCorrect = true;
                        const point = this.form.points[i];
                        if (point.notAudited == null || point.notAudited === false) {
                            pointIsCorrect = ValidateUtils.validateNotEmpty(point, ["pointStartTime", "pointEndTime", "crowNests", "magpieNests"]);
                            if (DateUtils.isTimeEarlier(point.pointStartTime, this.form.startTime)
                                || DateUtils.isTimeEarlier(point.pointEndTime, point.pointStartTime)
                                || DateUtils.isTimeEarlier(this.form.endTime, point.pointEndTime)) pointIsCorrect = false;
                            pointIsCorrect = ValidateUtils.validateBirds(point.birds, point.noBirds, ["species", "count", "sex"]) && pointIsCorrect;
                        }
                        if (point.error) delete point.error; // remove previous checks
                        if (!pointIsCorrect) {
                            point.error = true;
                            isCorrect = false;
                        }
                    }
                    const hdChecked = new Set();
                    // eslint-disable-next-line
                    this.form.habitatDataToChange.forEach((hd) => {
                        if (hdChecked.has(hd.name)) isCorrect = false;
                        hdChecked.add(hd.name);
                    });
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.points, "Punkt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.points, this.form.noFormControl);
            },
            readonly() {
                return SystemRole.isObserver() && this.form.state === "APPROVED";
            },
        },
    };
</script>

<style lang="scss">
</style>
