/* eslint-disable */
import L from "leaflet";
import {markRaw} from "vue";
// import {GestureHandling} from "leaflet-gesture-handling";
// import "leaflet-fullscreen";
import "leaflet-geosearch/dist/geosearch.css";

import "leaflet/dist/leaflet.css";
// import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
// import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";

export const MapMixin = {

    data() {
        return {
            map: null,
            mapInstanceId: "map",
        };
    },

    methods: {
        initMap() {
            L.Map.addInitHook("addHandler");
            const osmUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}";
            const satUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            const osmAttrib = "";
            const satAttrib = "";
            const osm = L.tileLayer(osmUrl, {maxZoom: 18, attribution: osmAttrib});
            const sat = L.tileLayer(satUrl, {maxZoom: 18, attribution: satAttrib})
            const map = new L.Map(this.mapInstanceId, {
                center: new L.LatLng(52.2297, 21.0122),
                // zoom: 7,
                zoomControl: false,
                gestureHandling: true,
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: "topleft",
                },
            });

            const mapLayers = {
                "Topograficzna": osm,
                "Ortofotomapa": sat,
            }

            L.control.layers(mapLayers).addTo(map);

            const provider = new OpenStreetMapProvider();

        const IconUrl = "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png";
        const Icon = new L.Icon({
            iconUrl: IconUrl,
            shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
            iconSize: [0, 0],
            iconAnchor: [12, 41],
            popupAnchor: [0, 0],
            shadowSize: [0, 0],
        });

        const searchControl = new GeoSearchControl({
            provider,
            marker: {
                // optional: L.Marker    - default L.Icon.Default
                icon: Icon,
                draggable: false,
            },
            autoClose: false,
            searchLabel: this.placeholderLanguage,
            style: "",
        });

        // map.addControl(searchControl);
        // L.control.layers({
        //     searchControl: map.addControl(searchControl),
        // }, {}, {});
        L.control.layers({
            osm: osm.addTo(map),
        }, {}, {});
        this.map = markRaw(map);
        },
        initCustomIdMap(customMapId) {
            if (customMapId !== null && customMapId !== "" && customMapId !== undefined && customMapId !== "map") {
                this.mapInstanceId = customMapId;
            }
            this.initMap();
        },
    },
};
