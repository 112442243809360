<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex column-gap-3 flex-direction-column-700">
                <CustomCalendar label="Data liczenia" name="countDate" class="w-4 max-width" v-model="position.controlDate"
                                :disabled="!editing" required :showErrors="showErrorMessages"
                                :customErrorMessage="getDateErrorMessage(position.controlDate)" />
                <CustomTimePicker label="Czas rozpoczęcia" name="startTime" class="w-4 max-width" v-model="position.startTime"
                                  :disabled="!editing" required :showErrors="showErrorMessages"/>
                <CustomTimePicker label="Czas zakończenia" name="endTime" class="w-4 max-width" v-model="position.endTime"
                                  :disabled="!editing" required :showErrors="showErrorMessages"
                                  :customErrorMessage="getTimeErrorMessage()"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column-700">
                <CustomCheckbox label="Brak ptaków" name="noBirds" class="w-2 max-width"
                                v-model="position.noBirds" :disabled="!editing" />
                <CustomInputNumber v-if="!position.noBirds" label="Liczba zajętych gniazd"
                                   name="occupiedNest" class="w-5 max-width" v-model="position.occupiedNests"
                                   :disabled="!editing" required :showErrors="showErrorMessages"/>
                <CustomInputNumber v-if="!position.noBirds" label="W tym gniazd na ziemi"
                                   name="occupiedNestsOnGround" class="w-5 max-width"
                                   v-model="position.occupiedNestsOnGround" :disabled="!editing" />

            </div>
            <div class="flex column-gap-3 flex-direction-column-700">
                <CustomCheckbox v-if="!position.noBirds" label="Ślady aktywności ludzkiej" name="humanTraces"
                                v-model="position.humanTraces" class="w-2 max-width" />
                <CustomInputTextArea v-if="!position.noBirds" label="Opis śladów"
                                     class="w-10 max-width" name="humanTracesDescription"
                                     :disabled="!editing || !position.humanTraces"
                                     v-model="position.humanTracesDescription" />
            </div>
            <div class="flex column-gap-3 flex-direction-column-1000">
                <CustomSelectOne v-if="!position.noBirds" label="Umiejscowienie kolonii" :items="dicts.habitatType"
                                 v-model="position.colonyLocation" class="w-4 max-width" name="colonyLocation"
                                 :disabled="!editing" required :showErrors="showErrorMessages"/>
                <CustomSelectOne v-if="!position.noBirds" label="Dominujący rodzaj drzew gniazdowych" :items="dicts.treeType"
                                 v-model="position.dominantTreeType" class="w-4 max-width" name="dominantTreeType"
                                 :disabled="!editing" required :showErrors="showErrorMessages"
                                 longLabelSelect/>
                <CustomSelectOne v-if="!position.noBirds" label="Metoda obserwacji"
                                 name="controlMethod" v-model="position.controlMethod"
                                 class="w-4 max-width" :items="dicts.controlMethod" required
                                 :showErrors="showErrorMessages" :disabled="!editing"/>
            </div>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMKOObservations",

        components: {
            FormObservationsTemplate,
            CustomSelectOne,
            ObservationsSelectPosition,
            CustomInputNumber,
            CustomCheckbox,
            CustomCalendar,
            CustomTimePicker,
            CustomInputTextArea,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMKOPosition()),
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        emits: ["update:step"],

        inject: ["showErrorMessages", "dicts", "editing", "controlTimeFrame"],

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        methods: {
            getTimeErrorMessage() {
                if (ValidateUtils.validateNotEmpty(this.position, ["startTime", "endTime"])
                    && DateUtils.isTimeEarlier(this.position.endTime, this.position.startTime)) {
                    return this.$t("message.validations.afterTime");
                }
                return "";
            },
            getDateErrorMessage(value) {
                if (value && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return "";
            },
        },

        watch: {
            /* eslint-disable-next-line */
            "position.noBirds"(value) {
                if (value) {
                    this.position.occupiedNests = null;
                    this.position.groundNests = null;
                }
            },
            /* eslint-disable-next-line */
            "position.humanTraces"(value) {
                if (!value) {
                    this.position.humanTracesDescription = "";
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";
@media screen and (max-width: 700px) {
    .flex-direction-column-700 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 1015px) {
    .flex-direction-column-1000 {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
