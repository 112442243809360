<template>
    <div class="programs-table programs-table-observer">
        <DataTable dataKey="code" :value="programs" selectionMode="single" lazy paginator
                   v-model:rows="page.limit" v-model:first="page.offset" :totalRecords="totalRecords"
                   @page="onPage" @sort="onPage" removableSort :rows="10" :rowsPerPageOptions="[5,10,20,50,100]"
                   :loading="loading" size="small" v-model:expandedRows="expandedRows" @row-click="expandRow"
                   v-model:filters="searchCriteria" filterDisplay="menu" @update:filters="search"
                   @row-expand="rowExpand" sortField="name" :sortOrder="1">
            <Column header="Program" field="name" class="w-4 program-header header-border"
                    filterField="program" :showFilterMatchModes="false" filter-menu-class="w-16rem"
                    sortable>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="program" v-model="filterModel.value" :items="filterLists.programs"
                                       filter itemLabel="" class="flex-1 w-0 mt-3" itemValue="" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Sezon" field="season" class="w-1 header-border"
                    filterField="season" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    {{ computedSeason(slotProps.data.season) }}
                </template>
                <template #filter="{filterModel}">
                    <CustomSelectOne v-if="loadLists" name="season" v-model="filterModel.value" :items="filterLists.seasons"
                                     filter :itemLabel="computedSeason" class="flex-1 w-0 mt-3" itemValue="" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Status" field="status" class="w-3 header-border"
                    filterField="state" :showFilterMatchModes="false" filter-menu-class="w-16rem">
                <template #body="slotProps">
                    {{ computedStatus(slotProps.data.status) }}
                </template>
                <template #filter="{filterModel}">
                    <CustomMultiSelect v-if="loadLists" name="state" v-model="filterModel.value" :items="filterLists.status"
                                       filter class="flex-1 w-0 mt-3" itemValue="key" />
                </template>
                <template #filterclear="{ filterCallback }"><FilterClear :callback="filterCallback" /></template>
                <template #filterapply="{ filterCallback }"><FilterApply :callback="filterCallback" /></template>
            </Column>
            <Column header="Ogólny postęp" field="progress" class="w-4 header-border">
                <template #body="slotProps">
                    <div class="flex gap-4 align-items-center">
                        <ProgressBar :progressPercentage="Number(slotProps.data.progress)" class="w-6" />
                        <span class="w-4">{{ slotProps.data.progress }}%</span>
                    </div>
                </template>
            </Column>
            <Column expander class="w-1 hidden" />
            <Column class="w-1 text-right">
                <template #body="slotProps">
                    <i v-if="slotProps.data.expand" class="pi pi-angle-down expand-icon cursor-pointer" />
                    <i v-else class="pi pi-angle-right expand-icon cursor-pointer" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div :class="`expand-animation expand-animation-${slotProps.data.code}`">
                    <ProgramsListInternalTable :program="slotProps.data.code" ref="programsListInternalTable" />
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
    import { ref } from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import ProgressBar from "@/components/ProgressBar.vue";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import ProgramsListInternalTable
        from "@/views/programsList/components/ProgramsListInternalTable.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FilterApply from "@/components/filters/FilterApply.vue";
    import FilterClear from "@/components/filters/FilterClear.vue";
    import {
        homeSearchPrograms,
        homeCountPrograms,
        homeFilterPrograms,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";

    export default {
        name: "ProgramsListTable",

        components: {
            FilterClear,
            FilterApply,
            CustomSelectOne,
            ProgramsListInternalTable,
            DataTable,
            Column,
            ProgressBar,
            CustomMultiSelect,
        },

        inject: ["showFinished"],

        data() {
            return {
                programEditions: [],
                searchCriteria: SearchCriteria.getClearSearchCriteriaProgramsList(),
                page: SearchCriteria.getClearPage(),
                totalRecords: null,
                expandedRows: ref([]),
                loading: false,
                programs: [],
                filterLists: {},
                loadLists: false,
            };
        },

        beforeMount() {
            this.page.sortField = "name";
            this.page.sortOrder = 1;
            this.search();
        },

        mounted() {
            homeFilterPrograms().then((response) => {
                this.filterLists = response.data;
                this.loadLists = true;
            });
        },

        methods: {
            expandRow(row) {
                const rows = document.querySelectorAll(".p-row-toggler");
                rows[row.index].click();
                row.data.expand = !row.data.expand;
            },
            clear() {
                if (this.$refs.programsListInternalTable) {
                    this.$refs.programsListInternalTable.clear();
                }
                this.searchCriteria = SearchCriteria.getClearSearchCriteriaProgramsList();
                this.search();
            },
            search() {
                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    this.expandedRows = ref([]);
                    const criteria = SearchCriteria.prepareCriteria(
                        this.searchCriteria,
                        this.page,
                        this.filterDescription,
                    );
                    homeSearchPrograms({body: {...criteria, finished: this.showFinished}})
                        .then((response) => {
                            this.programs = response.data;
                            return homeCountPrograms({body: {...criteria, finished: this.showFinished}});
                        })
                        .then((response) => {
                            this.totalRecords = response.data;
                            this.loading = false;
                        });
                }, 100);
            },
            onPage(event) {
                this.page = {
                    offset: event.first,
                    limit: event.rows,
                    sortField: event.sortField,
                    sortOrder: event.sortOrder,
                };
                this.search();
            },
            computedSeason(season) {
                if (season === "BREEDING") return "lęgowy";
                if (season === "WINTERING") return "zimowania";
                return "";
            },
            computedStatus(status) {
                return ProgramEditionState.getStateLabel(status);
            },
            rowExpand(data) {
                setTimeout(() => {
                    document.querySelector(`.expand-animation-${data.data.code}`).style.maxHeight = "5000px";
                }, 500);
            },
        },

        computed: {
            filterDescription() {
                return SearchCriteria.getSearchCriteriaProgramEditionFilterDescriptionHome();
            },
        },

        watch: {
            showFinished() {
                this.search();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";

    .programs-table-observer {
        .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
            color: var(--primary-color-text);
        }
        &.programs-table span[data-pc-section="sort"] svg {
            color: var(--primary-color-text);
        }
        .p-column-filter-menu-button, span[data-pc-section="sort"] svg {
            color: var(--primary-color-text);
        }
        .programs-list-internal-table .p-column-filter-menu-button {
            color: var(--text-color-secondary);
        }
        .p-column-title {
            font-size: 1rem;
            color: var(--surface-a);
        }
        .p-datatable .p-datatable-thead > tr > th {
            background-color: var(--secondary-color);
        }
        .p-datatable .p-datatable-tbody .p-datatable .p-datatable-thead > tr > th {
            background-color: rgba(5, 104, 57, 0.27);
            border-width: 0;
            .p-column-title {
                color: var(--color-black);
            }
            .p-datatable {
                border: 1px solid var(--surface-border);
            }
        }
        .p-datatable .p-datatable-tbody > tr:focus {
            outline: none;
        }
        .p-datatable .p-datatable-tbody > tr > td {
            background-color: var(--primary-color);
            color: var(--color-black);
            font-weight: normal;
            border: 1px solid var(--surface-border);
            border-top: none;
            border-left: none;
            &:first-child {
                border-left: 1px solid var(--surface-border);
            }
            &.header-border:nth-of-type(1) {
                border-right: none;
            }
            &.header-border:nth-of-type(2) {
                border-left: none;
                border-right: none;
            }
            &.header-border:nth-of-type(3) {
                border-left: none;
                border-right: none;
            }
            &.header-border:nth-of-type(4) {
                border-left: none;
                border-right: none;
            }
            &.header-border:nth-of-type(5) {
                border-left: none;
            }
            .expand-icon {
                font-size: 1.5rem;
            }
        }
        .p-datatable .p-datatable-tbody > tr > td.program-header {
            font-weight: bold;
        }
        .p-datatable .p-datatable-wrapper {
            & .p-datatable-row-expansion, .p-datatable-row-expansion td .p-datatable .p-datatable-wrapper {
                // border: 1px solid rgba(5, 104, 57, 0.27);
            }
        }
        a {
            display: block;
            color: var(--text-color);
            padding: 1rem;
        }
        .p-datatable-row-expansion, .p-datatable-row-expansion td {
            background-color: var(--surface-a) !important;
        }
        .p-datatable-tbody tr.p-datatable-row-expansion td, .p-datatable-tbody tr.p-datatable-row-expansion th {
            padding: 1rem 2.5rem;
        }

        .program-button {
            border: 2px solid var(--secondary-color);
            border-radius: 20px;
            min-width: 60px;
            text-align: center;
            &:hover {
                border-color: var(--secondary-color-mid);
            }
        }
    }
    .expand-animation {
        transition: 2s all;
        max-height: 0;
        overflow: hidden;
    }
</style>
