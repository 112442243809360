<template>
    <ImportErrorsDialog v-model="showImportErrorsDialog" :errors="errors" />
    <Dialog header="Importuj koordynatorów regionalnych" v-model:visible="visible" class="import-cast-dialog"
            :closable="false" modal :draggable="false">
        <template #header>
            <div>
                <div class="alert-info">Uwaga! Zaimportowanie obsady spowoduje usunięcie dotychczasowej.</div>
                <CustomFileUpload @importFile="importFile" :multiple="false" accept=".xlsx, .xls"
                                  class="import-cast-file-upload" />
            </div>
        </template>
        <div class="dialog-file-upload-buttons import-cast-dialog-buttons w-100 h-100">
            <CustomButton label="Anuluj" class="flex justify-content-center red-button" @click="visible = false"
                          bgColor="transparent" />
            <CustomButton label="Importuj koordynatorów regionalnych" class="green-button"
                          @click="importCast" :disabled="waitForServer" />
        </div>
    </Dialog>
</template>

<script>
    import axios from "axios";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomFileUpload from "@/components/CustomFileUpload.vue";
    import ImportErrorsDialog from "@/components/formSharedComponents/dialogs/ImportErrorsDialog.vue";

    export default {
        name: "ImportRegionalCoordinatorsDialog",

        components: {
            CustomFileUpload,
            CustomButton,
            Dialog,
            ImportErrorsDialog,
        },

        props: {
            modelValue: null,
        },

        emits: ["reloadProgramRegionalCoord"],

        data() {
            return {
                importRegionalCoordinators: null,
                waitForServer: false,
                showImportErrorsDialog: false,
                errors: null,
            };
        },

        methods: {
            importFile(value) {
                this.importRegionalCoordinators = value;
            },

            importCast() {
                this.waitForServer = true;
                // importCastFromExcel({programEditionId: this.$route.params.id, file: this.importRegionalCoordinators})
                //     .then((response) => {
                //         console.log(response);
                //     });
                axios.post(
                    `${process.env.VUE_APP_API_URL}/programEdition/regional-cast/import/${this.$route.params.id}`,
                    this.importRegionalCoordinators,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.waitForServer = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                        this.$emit("reloadProgramRegionalCoord");
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 500) {
                            this.errors = {code: "Niepoprawny plik"};
                        } else {
                            this.errors = err.response.data;
                        }
                        this.waitForServer = false;
                        this.showImportErrorsDialog = true;
                        this.visible = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
@import "../../../../assets/theme/mytheme/variables";
@import "../../../assets/scss/dialogs";
</style>
