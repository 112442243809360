<template>
    <Toast />
    <Dialog class="new-points-dialog" header="Dodaj nowy punkt" :draggable="false" v-model:visible="visible" modal>
        <div class="new-points-dialog-container flex flex-column">
            <div class="new-points-dialog-content flex justify-content-between mb-5 gap-8">
                <div class="new-points-dialog-content__right">
                    <CustomInputText label="Nazwa punktu" v-model="newPoint.name" name="newPointName"
                                     required :showErrors="showErrorMessages"
                                     @input="validateNamePoint" :class="{ 'red-outline-input': isDisabled }" />
                    <CustomInputNumber label="Koordynaty N" v-model="newPoint.coordinates.latitude"
                                       :maxDigits="6" @change="updateMap" name="newPointLat"
                                       required :showErrors="showErrorMessages" />
                    <CustomInputNumber label="Koordynaty E" v-model="newPoint.coordinates.longitude"
                                       :maxDigits="6" @change="updateMap" name="newPointLng"
                                       required :showErrors="showErrorMessages" />
                    <div v-if="isDisabled" class="max-w-14rem">Punkt o takiej nazwie już istnieje</div>
                    <div v-if="!markerInside && newPoint.coordinates.latitude != null
                             && newPoint.coordinates.longitude != null"
                         class="max-w-14rem outside-error">Podane koordynaty wskazują na punkt poza obszarem</div>
                </div>
                <div class="new-points-dialog-content__left">
                    <div class="new-points-map"
                         style="height: 400px; width: 600px;">
                        <MapSingleMarker v-model="mapCoordinates" mapId="newPointMap" height="400px"
                                         @emitCoor="setLatLng" :zoomControls="false"
                                         :field="field.superField ? field.superField.shape : field.shape"
                                         :subfield="field.superField ? field.shape : []"
                                         @isMarkerInside="isMarkerInside" />
                    </div>
                </div>
            </div>
            <CustomButton label="Dodaj nowy punkt" :disabled="isDisabled" icon="pi pi-plus"
                          rowReverse bgColor="var(--secondary-color)"
                          color="var(--surface-a)" class="align-self-end" @click="addEditPoint" />
        </div>
    </Dialog>
</template>

<script>
    // import _ from "lodash";
    import Dialog from "primevue/dialog";
    import Toast from "primevue/toast";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import {MapMixin} from "@/mixins/MapMixin";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";

    export default {
        name: "NewPointZeroDialog",

        mixins: [MapMixin],

        components: {
            MapSingleMarker,
            Dialog,
            CustomButton,
            CustomInputNumber,
            CustomInputText,
            Toast,
        },

        emits: ["update:modelValue", "update:allPoints"],

        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
                default: () => {},
            },
            programCode: null,
            allPoints: null,
        },

        data() {
            return {
                newPoint: this.getClearNewPoint(),
                mapCoordinates: [],
                showErrorMessages: false,
                isDisabled: false,
                markerInside: true,
            };
        },

        methods: {
            validateNamePoint() {
                this.internalAllPoints.forEach((point) => {
                    this.isDisabled = point.name.toLowerCase() === this.newPoint.name.toLowerCase();
                });
            },
            addEditPoint() {
                if (this.markerInside && this.validateNewPointData(this.newPoint)) {
                    this.showErrorMessages = false;
                    this.newPoint.coordinates.longitude.toString().replace(",", ".");
                    this.newPoint.coordinates.latitude.toString().replace(",", ".");
                    if (this.isEdited) {
                        this.visible = false;
                    } else {
                        this.newPoint.id = this.internalAllPoints[this.internalAllPoints.length - 1]?.id ? this.internalAllPoints[this.internalAllPoints.length - 1].id + 1 : 1;
                        this.internalAllPoints.push(this.newPoint);
                    }
                    this.visible = false;
                } else {
                    this.showErrorMessages = true;
                }
            },
            setLatLng(coordinates) {
                this.newPoint.coordinates.latitude = coordinates.lat;
                this.newPoint.coordinates.longitude = coordinates.lng;
            },
            updateMap() {
                // if (this.newPoint.coordinates.latitude == null
                //     || this.newPoint.coordinates.longitude == null) return;
                this.mapCoordinates = [
                    this.newPoint.coordinates.latitude,
                    this.newPoint.coordinates.longitude,
                ];
            },
            validateNewPointData(newPoint) {
                return !(newPoint.name === "" || newPoint.name === null
                    || newPoint.coordinates.longitude === "" || newPoint.coordinates.longitude === null
                    || newPoint.coordinates.latitude === "" || newPoint.coordinates.latitude === null);
            },
            getClearNewPoint() {
                return {
                    id: null,
                    name: null,
                    coordinates: {
                        longitude: null,
                        latitude: null,
                    },
                };
            },
            isMarkerInside(value) {
                this.markerInside = value;
            },
        },

        computed: {
            visible: {
                get() { return this.modelValue; },
                set(value) {
                    this.newPoint = this.getClearNewPoint();
                    this.mapCoordinates = [];
                    this.$emit("update:modelValue", value);
                },
            },
            internalAllPoints: {
                get() { return this.allPoints; },
                set(value) { this.$emit("update:allPoints", value); },
            },
        },

        watch: {
            internalAllPoints: {
                handler(value) { this.$emit("update:allPoints", value); },
                deep: true,
            },
            "newPoint.coordinates.latitude"() {
                this.updateMap();
            },
            "newPoint.coordinates.longitude"() {
                this.updateMap();
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";
    .outside-error {
        color: $color-red;
    }
    .new-points-dialog-content {
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            .new-points-map {
                width: 80vw !important;
            }
        }
    }
</style>
