<template>
    <div v-if="loaded" class="form-point-zero flex flex-column p-3">
        <NewPointZeroDialog v-model="showNewPointDialog" :field="formZero.field"
                            v-model:allPoints="formZero.formPointZeros" />
        <ProgramDetails :form="formZero" />
        <div class="mt-5 flex align-items-center justify-content-between flex-wrap row-gap-3">
            <CustomSelectOne label="Liczba punktów" :items="allNumberOfPoints" :showClear="false"
                             v-model="numberOfPoints" class="w-2 mb-0"
                             name="numberOfPoints" itemLabel=""
                             style="min-width: 172px;" />
            <div class="flex gap-2">
                <CustomButton label="Dodaj punkt" class="justify-content-center" bgColor="#33795B"
                              color="white" @click="add()"
                              :disabled="this.formZero.formPointZeros.length === this.numberOfPoints" />
                <CustomButton label="Edytuj punkty" class="justify-content-center" :bgColor="editPoints ? '#33795B' : 'white'"
                              :color="editPoints ? 'white' : ''" @click="editPoints = !editPoints" />
            </div>
            <div class="flex gap-2">
                <CustomIconButton icon="pi pi-chevron-left" label="Poprzednie" rowReverse
                                  @click="goNext(formZero.formPointZeros,true)" />
                <CustomIconButton icon="pi pi-chevron-right" label="Następne" @click="goNext(formZero.formPointZeros)" />
            </div>
        </div>
        <div class="flex mb-4 mt-3 gap-8 form-point-zero-main-container">
            <div style="height: 600px;" class="w-7 form-point-zero-map">
                <MapMultiMarkers mapId="pointsZeroFormMap" height="600px" :isOn="false" :field="field" markerPopup
                                 :positions="formZero.formPointZeros" :markerTypes="markerTypes" openPopupOnClick
                                 @mouseoverMarker="(id) => hoverPoint = id"
                                 @mouseoutMarker="hoverPoint = null"
                                 @clickMarker="(id) => clickedPoint = id"
                                 idField="id" @dragendMarker="updateCoordinates">
                    <template v-slot:markerPopup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.position.name }}
                        </div>
                    </template>
                    <!-- <template v-slot:markerPopupFull="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.position.name }}
                        </div>
                        <div class="marker-popup-content" style="min-width: 290px">
                            <div>
                                <p v-show="!changeCoordinates[slotProps.index]"
                                   class="cursor-pointer w-max" @click="changeDraggable(slotProps.index)">
                                    Edytuj położenie punktu
                                </p>
                            </div>
                            <div v-if="changeCoordinates[slotProps.index]"
                                 class="input-coordinates flex flex-column mt-4 align-items-center">
                                <CustomInputText label="Nazwa" v-model="formZero.formPointZeros[slotProps.index].name" />
                                <div class="input-coordinates flex gap-2">
                                    <CustomInputNumber class="w-15rem" label="Koordynaty N" name="latitude" :maxDigits="6" :minDigits="6"
                                                       v-model="formZero.formPointZeros[slotProps.index].coordinates.latitude" />
                                    <CustomInputNumber class="w-15rem" label="Koordynaty E" name="longitude" :maxDigits="6" :minDigits="6"
                                                       v-model="formZero.formPointZeros[slotProps.index].coordinates.longitude" />
                                </div>
                                <CustomButton label="Zamknij edycję" @click="changeDraggable(slotProps.index)"
                                              bgColor="var(--secondary-color)" color="var(--surface-a)"/>
                            </div>
                        </div>
                    </template> -->
                </MapMultiMarkers>
            </div>
            <div class="w-5 form-point-zero-coor">
                <div class="mb-4 text-2xl font-bold">Lista punktów:</div>
                <ul class="points-list-container">
                    <li v-for="point in formZero.formPointZeros" :key="point.id" :title="point.name"
                        class="point-list-single-element text-2xl"
                        @click="() => clickedPoint = point.id"
                        @mouseover="() => hoverPoint = point.id"
                        @focus="() => hoverPoint = point.id"
                        @mouseout="() => hoverPoint = null"
                        @blur="() => hoverPoint = null">
                        <div class="flex align-items-center justify-content-between">
                            <span>{{ getName(point) }}</span>
                            <div class="flex gap-2">
                                <!-- <CustomButton icon="pi pi-pencil" @click="edit(point)" /> -->
                                <CustomInputNumber v-if="editPoints" class="w-12rem" label="Koordynaty N" name="latitude" :maxDigits="6" :minDigits="6"
                                                   v-model="point.coordinates.latitude"
                                                   style="margin-bottom: 0 !important; margin-top: 0.5rem;" />
                                <CustomInputNumber v-if="editPoints" class="w-12rem" label="Koordynaty E" name="longitude" :maxDigits="6" :minDigits="6"
                                                   v-model="point.coordinates.longitude"
                                                   style="margin-bottom: 0 !important; margin-top: 0.5rem;" />
                                <CustomButton icon="pi pi-trash" @click="deletePoint(point)"
                                              style="margin-bottom: 0 !important; margin-top: 0.5rem;" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex justify-content-end flex-wrap gap-3">
            <CustomButton v-if="canApprove" class="form-button approve-button" label="Zatwierdź"
                          bgColor="var(--secondary-color)" color="var(--surface-a)" @click="approve" />
            <!-- <CustomButton v-if="canReject" class="form-button reject-button" label="Odrzuć"
                          bgColor="rgba(193, 197, 204, 0.4)" color="#EA3030" />
            <CustomButton v-if="canSendToVerification" class="form-button send-to-verification-button w-auto"
                          label="Wyślij do weryfikacji" bgColor="rgba(193, 197, 204, 0.4)" /> -->
            <CustomButton v-if="canSave" class="form-button save-button" label="Zapisz"
                          bgColor="rgba(193, 197, 204, 0.4)" @click="save()" />
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import _ from "lodash";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {computed} from "vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomIconButton from "@/components/form/CustomIconButton.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import ProgramDetails from "@/components/formSharedComponents/ProgramDetails.vue";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        getPointZero,
        editPointZero,
        approveFormPointZero,
    } from "@/swagger/vue-api-client";
    import MapMultiMarkers from "@/components/map/MapMultiMarkers.vue";
    import NewPointZeroDialog from "@/components/formSharedComponents/dialogs/NewPointZeroDialog.vue";

    export default {
        name: "FormPointZeroView",

        components: {
            CustomButton,
            CustomInputNumber,
            CustomInputText,
            CustomIconButton,
            ProgramDetails,
            CustomSelectOne,
            MapMultiMarkers,
            NewPointZeroDialog,
            DataTable,
            Column,
        },

        data() {
            return {
                loaded: false,
                editing: true,
                showErrorMessages: false,
                formZero: {},
                field: [],
                deleteCircle: null,
                activeIndex: null,
                previousHover: null,
                previousClicked: null,
                color: "grey",
                colorDisabled: "lightGrey",
                colorClickedOrHover: "red",
                hoverIndex: null,
                clickedIndex: null,
                mapReloader: 1,
                numberOfPoints: null,
                allNumberOfPoints: [],
                markerTypes: new Map(),
                iconOnHover: {
                    color: "red",
                    size: "regular",
                    popup: true,
                    dontHidePopup: false,
                },
                hoverPoint: null,
                clickedPoint: null,
                activePoint: null,
                showNewPointDialog: false,
                changeCoordinates: [],
                editPoints: false,
            };
        },

        provide() {
            return {
                programCode: computed(() => this.formZero.programName),
            };
        },

        beforeMount() {
            this.getFormZero();
            this.previousHover = this.hoverIndex;
            this.previousClicked = this.clickedIndex;
        },

        methods: {
            getFormZero() {
                getPointZero({formId: this.$route.params.id})
                    .then((response) => {
                        this.formZero = response.data;
                        this.formZero.formPointZeros.forEach((p) => {
                            delete Object.assign(p, {["coordinates"]: p["point"] })["point"];
                        });
                        console.log(this.formZero);
                        this.numberOfPoints = this.formZero.suggestedNumberOfPoints;
                        for (let i = 0; i < this.formZero.suggestedNumberOfPoints; i += 1) {
                            this.allNumberOfPoints.push(i + 1);
                        }
                        this.field = this.formZero.field.shape;
                        if (this.formZero.formPointZeros.length > 0) {
                            this.activePoint = this.formZero.formPointZeros[0];
                        }
                        for (let i = 0; i < this.formZero.formPointZeros.length; i += 1) {
                            this.changeCoordinates.push(false);
                        }
                        if (this.formZero.formPointZeros.length > 0) {
                            this.goNext(this.formZero.formPointZeros);
                        };
                        this.setMarkerTypes(this.hoverPoint, this.clickedPoint);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loaded = false;
                    });
            },
            add() {
                this.showNewPointDialog = true;
            },
            deletePoint(point) {
                const index = this.formZero.formPointZeros.indexOf(point);
                this.formZero.formPointZeros.splice(index, 1);
            },
            setMarkerTypes(hover, clicked) {
                // creates actual map which keeps for every id an array with icon color and icon size
                this.formZero.formPointZeros.forEach((point, index) => {
                    if (point.id !== null
                        && (point.id === hover || point.id === clicked)) {
                        this.markerTypes.set(this.formZero.formPointZeros[index].id, {
                            color: "red",
                            size: "regular",
                            popup: true,
                            dontHidePopup: point.id === clicked,
                            draggable: this.changeCoordinates[index],
                        });
                    } else {
                        this.markerTypes.set(this.formZero.formPointZeros[index].id, {
                            color: "lightGrey",
                            size: "small",
                            popup: false,
                            dontHidePopup: false,
                        });
                    }
                });
            },
            changeDraggable(value) {
                this.formZero.formPointZeros.forEach((fPZ, i) => {
                    const markerType = this.markerTypes.get(fPZ.id);
                    markerType.draggable = value;
                    this.markerTypes.set(fPZ.id, markerType);
                    this.changeCoordinates[i] = markerType.draggable;
                });
                // const markerType = this.markerTypes.get(this.formZero.formPointZeros[i].id);
                // markerType.draggable = !markerType.draggable;
                // this.markerTypes.set(this.formZero.formPointZeros[i].id, markerType);
                // this.changeCoordinates[i] = markerType.draggable;
            },
            updateCoordinates(coordinates, positionId) {
                for (let i = 0; i < this.formZero.formPointZeros.length; i += 1) {
                    if (this.formZero.formPointZeros[i].id === positionId) {
                        this.formZero.formPointZeros[i].coordinates = {latitude: coordinates.lat, longitude: coordinates.lng};
                    }
                }
            },
            goNext(data, previous = false) {
                const active = document.querySelector(".focus-point");
                const pointList = Array.from(document.getElementsByClassName(
                    "point-list-single-element",
                ));
                let index = pointList.indexOf(active);
                if (active === null) {
                    index = previous ? (pointList.length - 1) : 0;
                } else if (index < pointList.length) {
                    document.querySelector(".focus-point").classList.remove("focus-point");
                    if (previous) {
                        index = Math.max(0, index - 1);
                    } else {
                        index = Math.min(pointList.length - 1, index + 1);
                    }
                }
                pointList[index]?.classList?.add("focus-point");
                pointList[index]?.classList?.add("clicked");
                const localpoint = data[index];
                if (localpoint.coordinates === null) {
                    localpoint.coordinates = {
                        longitude: null,
                        latitude: null,
                    };
                }
                this.clickedPoint = localpoint.id;
                this.activePoint = localpoint;
            },
            goPrevious() {
                if (this.clickedIndex > 0) {
                    this.clickedIndex -= 1;
                    this.updateClickedOrHover(this.clickedIndex, "clicked");
                }
            },
            getName(point) {
                let name = point.name;
                if (point.commonName != null && point.commonName !== "") name += " (" + point.commonName + ")";
                return name;
            },
            save(withApproval = false) {
                const request = [];
                this.formZero.formPointZeros.forEach((p) => {
                    request.push({
                        id: p.id,
                        name: p.name,
                        point: p.coordinates,
                    });
                });
                editPointZero({formId: this.$route.params.id, body: request})
                    .then(() => {
                        if (withApproval) {
                            approveFormPointZero({formId: this.$route.params.id})
                                .then(() => {
                                    this.$router.push(`/monitoring-program/${this.formZero.programEditionId}`);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else {
                            this.$router.push(`/monitoring-program/${this.formZero.programEditionId}`);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
            },
            approve() {
                this.save(true);
            },
        },

        computed: {
            isCoordinator() {
                return SystemRole.isMainCoordinator() || SystemRole.isRegionalCoordinator()
                    || SystemRole.isNationalCoordinator();
            },
            canApprove() {
                return this.isCoordinator && (this.editing || this.formZero.state === "SENT_TO_VERIFICATION");
            },
            canReject() {
                return this.isCoordinator && this.formZero.state === "SENT_TO_VERIFICATION";
            },
            canSendToVerification() {
                return SystemRole.isObserver() && this.formZero.state === "DRAFT";
            },
            canSave() {
                return this.editing && this.formZero.state !== "APPROVED";
            },
        },

        watch: {
            clickedPoint(value) {
                if (document.querySelector(".focus-point")) {
                    document.querySelector(".focus-point").classList.remove("focus-point");
                }
                if (document.querySelector(".clicked")) {
                    document.querySelector(".clicked").classList.remove("clicked");
                }
                this.formZero.formPointZeros.forEach((point) => {
                    if (point.id === value) {
                        const index = this.formZero.formPointZeros.indexOf(point);
                        const pointsList = Array.from(document.getElementsByClassName(
                            "point-list-single-element",
                        ));
                        pointsList[index]?.classList?.add("focus-point");
                        pointsList[index]?.classList?.add("clicked");
                        if (point.coordinates === null) {
                            point.coordinates = {
                                longitude: null,
                                latitude: null,
                            };
                        }
                        this.clickedPoint = point.id;
                        this.activePoint = point;
                    }
                });
                this.setMarkerTypes(this.hoverPoint, value);
            },
            hoverPoint(value) {
                if (value === null) {
                    const pointsList = Array.from(document.getElementsByClassName(
                        "focus-point",
                    ));
                    for (let i = 0; i < pointsList.length; i += 1) {
                        if (!pointsList[i].classList.contains("clicked")) {
                            pointsList[i].classList.remove("focus-point");
                        }
                    }
                } else {
                    this.formZero.formPointZeros.forEach((point) => {
                        if (point.id === value) {
                            const index = this.formZero.formPointZeros.indexOf(point);
                            const pointsList = Array.from(document.getElementsByClassName(
                                "point-list-single-element",
                            ));
                            pointsList[index]?.classList?.add("focus-point");
                        }
                    });
                }
                this.setMarkerTypes(value, this.clickedPoint);
            },
            activePoint: {
                handler(value) {
                    this.clickedPoint = value.id;
                },
                deep: true,
            },
            formZero: {
                handler() {
                    this.setMarkerTypes(this.hoverPoint, this.clickedPoint);
                },
                deep: true,
            },
            numberOfPoints(value) {
                this.formZero.formPointZeros.splice(value);
            },
            editPoints(value) {
                this.changeDraggable(value);
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";
.form-point-zero {
    .points-list {
        margin-top: 2rem;
        &-header {
            font-size: 1rem;
        }
        &-container {
            display: flex;
            // flex-wrap: wrap;
            flex-direction: column;
            padding-left: 0;
            list-style: none;
            height: 500px;
            overflow-y: auto;
            .point-list-single-element {
                border-left: 2px solid var(--primary-darker-color);
                font-size: 1.28rem;
                position: relative;
                cursor: pointer;
                padding: 0.5rem 1.5rem;
                &:hover {
                    background-color: $secondaryLightColor;
                    &::after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        // width: 15%;
                        right: 0;
                        top: 0;
                        background-color: $color-white;
                    }
                }
                &.focus-point {
                    background-color: $secondaryLightColor;
                    font-weight: bold;
                    border-color: var(--secondary-color);
                    color: var(--color-black);
                    &::after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        // width: 15%;
                        right: 0;
                        top: 0;
                        background-color: var(--surface-a);
                    }
                }
                &::before {
                    display: block;
                    content: attr(title);
                    font-weight: bold;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .form-point-zero-main-container {
        width: 100vw;
        flex-direction: column;
        align-items: center;
        .form-point-zero-map {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .form-point-zero-map, .form-point-zero-coor {
            width: 90% !important;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
