<template>
    <DataTable :value="internalPrograms" dataKey="id" :loading="loading"
               size="small" scrollable scroll-height="80vh" :key="internalPrograms.length"
               removableSort>
        <Column header="Kod" field="key" headerClass="pl-5" bodyClass="pl-5" sortable />
        <Column header="Nazwa" field="name" class="w-2" sortable />
        <Column header="Data początkowa" field="startTime" style="width: 110px;"/>
        <Column header="Czas upływu kontroli krajowej" field="regionalDeadline" style="width: 110px;"/>
        <Column header="Czas upływu kontroli regionalnej" field="nationalDeadline" style="width: 110px;"/>
        <Column header="Czas upływu głównej kontroli" field="mainDeadline" style="width: 110px;"/>
        <Column header="Z pominięciem koordynatorów regionalnych" field="hasRegionalCoordinates" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(!slotProps.data.hasRegionalCoordinates) }}
            </template>
        </Column>
        <Column header="Sugerowane koordynaty" field="suggestedGeographicCoordinates" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.suggestedGeographicCoordinates) }}
            </template>
        </Column>
        <Column header="Wszystkie kontrole" field="allControls" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.allControls) }}
            </template>
        </Column>
        <Column header="Posiada podpowierzchnie" field="hasSubfields" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.hasSubfields) }}
            </template>
        </Column>
        <Column header="Posiada dane siedliskowe" field="hasHabitatData" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.hasHabitatData) }}
            </template>
        </Column>
        <Column header="Wszystkie gatunki" field="hasAllSpecies" style="width: 110px;">
            <template #body="slotProps">
                {{ computedBoolean(slotProps.data.hasAllSpecies) }}
            </template>
        </Column>
        <Column header="Działania" headerClass="pr-5" bodyClass="pr-5">
            <template #body="slotProps">
                <div class="flex flex-wrap gap-2">
                    <CustomButton label="Edytuj" @click="navigateToProgram(slotProps.data.key)"
                                  icon="pi pi-pencil" />
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
    // import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {getAllPrograms} from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton";

    export default {
        name: "ProgramDictTable",

        components: {
            CustomButton,
            DataTable,
            Column,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                programs: null,
                loading: false,
                internalPrograms: [],
            };
        },

        beforeMount() {
            this.loading = true;
            getAllPrograms()
                .then((response) => {
                    this.programs = response.data;
                    this.internalPrograms = this.programs;
                    this.loading = false;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
        },

        methods: {
            navigateToProgram(id) {
                this.$router.push(`/program/${id}`);
            },
            computedBoolean(value) {
                if (value) return "Tak";
                if (value === false) return "Nie";
                return null;
            },
        },

        watch: {
            searchCriteria: {
                handler(value) {
                    this.internalPrograms = [];
                    this.programs.forEach((p) => {
                        if (p.key.indexOf(value.key.toUpperCase()) > -1) {
                            this.internalPrograms.push(p);
                        }
                    });
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
